import { useSelectedPropertyContext } from "./SelectedPropertyProvider";

export const useCurrencyProvider = ({
  locale = null,
  currency = null,
} = {}) => {
  const { selected_locale, currency_code } = useSelectedPropertyContext();
  return {
    currency: (amount: number | bigint, code = null) =>
      new Intl.NumberFormat(locale || selected_locale, {
        style: "currency",
        currency: code || currency || currency_code,
      }).format(amount),
    currencyCode: currency || currency_code,
  };
};
