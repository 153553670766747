import {
  AutocompleteRenderInputParams,
  Button,
  Chip,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField as MuiTextField,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useSelectedPropertyContext } from "../components/SelectedPropertyProvider";
import {
  useExpenseTypesSubscription,
  useInsertExpenseMutation,
  usePropertyExpensesSubscription,
} from "../__generated__/types";
import { bgBG, DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Close, PhotoCamera } from "@mui/icons-material";
import { Field, Form, Formik } from "formik";
import { Autocomplete, Select, TextField } from "formik-mui";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import stc from "string-to-color";
import { useCurrencyProvider } from "../components/CurrencyPovider";
import { translations } from "../translations";

const ExpenseForm = ({ onSubmit }) => {
  const { selected } = useSelectedPropertyContext();
  const { data, loading, error } = useExpenseTypesSubscription({
    variables: { _eq: selected },
  });
  const { selected_locale } = useSelectedPropertyContext();
  if (loading) return null;
  if (error) return <div>{error.message}</div>;
  return (
    <Formik
      initialValues={{ type: null, amount: null, note: null }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required(
          translations[selected_locale].required_field
        ),
        amount: Yup.number()
          .nullable()
          .required(translations[selected_locale].required_field),
        note: Yup.string().nullable(),
      })}
      onSubmit={(values, actions) => {
        onSubmit(values);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ isSubmitting, isValid, dirty, touched, errors }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="type"
                  type="text"
                  component={Autocomplete}
                  options={data?.expense.map((i) => i.type) || []}
                  required
                  fullWidth
                  renderOption={(props, option) => (
                    <li {...props}>
                      {!data?.expense.map((i) => i.type).includes(option) ? (
                        <Chip
                          label={`${translations[selected_locale].new}: ${option}`}
                          variant="outlined"
                          sx={{
                            backgroundColor: stc(option),
                            color: (theme) => {
                              return theme.palette.getContrastText(stc(option));
                            },
                          }}
                        />
                      ) : (
                        <Chip
                          label={option}
                          variant="outlined"
                          sx={{
                            backgroundColor: stc(option),
                            color: (theme) => {
                              return theme.palette.getContrastText(stc(option));
                            },
                          }}
                        />
                      )}
                    </li>
                  )}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <MuiTextField
                      {...params}
                      name="type"
                      type="text"
                      error={touched["type"] && !!errors["type"]}
                      label={translations[selected_locale].type}
                      variant="outlined"
                      required
                    />
                  )}
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  filterOptions={(options, params) => {
                    const filtered = createFilterOptions()(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`${inputValue}`);
                    }
                    return filtered;
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="amount"
                  label={translations[selected_locale].amount}
                  type="number"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="note"
                  label={translations[selected_locale].note}
                  type="text"
                  multiline
                  rows={2}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  {translations[selected_locale].submit}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const ExpensePage = () => {
  const { selected, selected_locale, currency_code } =
    useSelectedPropertyContext();
  const [open, setOpen] = React.useState(false);
  const { data, loading, error } = usePropertyExpensesSubscription({
    variables: { id: selected },
  });
  const [insertExpense] = useInsertExpenseMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { currency } = useCurrencyProvider();

  if (loading || error) return null;

  const COLUMNS = [
    {
      field: "id",
      headerName: translations[selected_locale].number_label,
    },
    {
      field: "type",
      headerName: translations[selected_locale].type,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.value}
            variant="outlined"
            sx={{
              backgroundColor: stc(params.value),
              color: (theme) => {
                return theme.palette.getContrastText(stc(params.value));
              },
            }}
          />
        );
      },
    },
    {
      field: "amount",
      headerName: translations[selected_locale].amount,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "note",
      headerName: translations[selected_locale].note,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "created_at",
      headerName: translations[selected_locale].created_at,
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) =>
        `${dayjs(params.value).format("HH:ss YYYY-MM-DD")}`,
    },
    {
      field: "updated_at",
      headerName: translations[selected_locale].updated_at,
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) =>
        `${dayjs(params.value).format("HH:ss YYYY-MM-DD")}`,
    },
  ];

  return (
    <Container sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
      <Button
        color="secondary"
        variant="contained"
        sx={{ mb: 1, alignSelf: "flex-end" }}
        onClick={() => setOpen(true)}
      >
        {translations[selected_locale].add_expense}
      </Button>
      <DataGrid
        hideFooter
        error={error?.message}
        loading={loading}
        autoHeight
        checkboxSelection={false}
        rows={
          data?.property_by_pk?.expenses.map((item, index, arr) => {
            return {
              ...item,
              amount: currency(item.amount, item.currency_code),
              id: arr.length - index,
            };
          }) || []
        }
        columns={COLUMNS}
        disableSelectionOnClick
        localeText={translations[selected_locale].datagrid}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {translations[selected_locale].add_expense}{" "}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ExpenseForm
            onSubmit={async (values) => {
              await insertExpense({
                variables: {
                  type: values.type,
                  amount: values.amount,
                  note: values.note,
                  property_id: selected,
                  currency_code,
                },
              });
              enqueueSnackbar(translations[selected_locale].expense_saved, {
                variant: "success",
              });
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ExpensePage;
